import { useUserStore } from '@/stores/user';
export default defineComponent({
  setup: function setup() {
    var userStore = useUserStore();
    onMounted(function () {
      void userStore.logoutUser();
    });
  },
  head: function head() {
    return {
      title: 'Logout'
    };
  }
});